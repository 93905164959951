import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import SubscriptionRenewal from "../../components/subscription/SubscriptionRenewal";
import ProfileService from "../../../service/profile";
import Payments from "../../../service/common/PaymentApi";
import { withRouter } from "react-router-dom";
import style from "../../components/package/package.module.scss";
import companyName from "../../../store/mobxStore/companyName";
import { PaymentMethods } from "../../components/common";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import Spinner from "../../shared/Spinner";
import paymentStyle from "./payments.module.css"

function SubscriptionPay(props) {
  const [jwt, setJwt] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);

  const [state, setState] = useState({
    product: [],
    selectedTab: 0,
    payment_methods: [],
    selectedPeyment: "",
    alert: {
      show: false,
      message: "",
      varient: "",
    },
    ewalletData: {
      username: "",
      password: "",
    },

    epinData: {
      pinArray: [],
    },
    paypal: {
      orderId: "",
      payeerId: "",
    },
    loader: false,
    loading: true,
    paymentData: "",
    userDetails: "",
    cardDetails: "",
    trustCardDetails: ""
  });

  const [clientSecret, setClientSecret] = useState("");
  const [checked, setChecked] = useState();
  const currency = useSelector((state) => state.curr);

  const Service = new Payments();
  const service = new ProfileService();
  // this.loadDetails();

  const updateSubscription = async (userPaymentId) => {
    const data = {
      payement_method: state.selectedPeyment.code,
      product_deatils: state.product,
    };
    if (userPaymentId) {
      data["user_payment_id"] = userPaymentId
    }

    const response = await service.subscriptionUpdate(data);
    console.log("response 99 ", response?.data, data.payement_method);
    if (response?.data?.status) {
      setPaymentResponse({ ...paymentResponse, status: true, data: "Subscription Updated" })
    } else {
      setPaymentResponse({ ...paymentResponse, status: false, data: `Subscription Failed (${response?.data})` })
    }
    if (response && response.data && data.payement_method === "now-payment") {
      window.location.href = response.data;
    }
    if (response && response.data && data.payement_method === "trust-payment") {
      if (response?.data?.status) window.location.href = '/dashboard';
      return response.data;
    }
  };

  const { Id } = useParams();
  useEffect(() => {
    // Service.createPayment(props.product, "trust-payment").then((res) => {
    //   if (res.status) {
    //    setJwt(res.data)
    //   }
    // });

    loadDetails();
    loadPaymentData();
  }, [state.alert.show]);

  // componentDidMount() {
  //   this.loadDetails();
  //   this.loadPaymentData();
  // };

  //subscription change

  const handleSubscription = (e) => {
    if (e.target.checked) {
      if (window.confirm(t("validation.sure_subscribe"))) {
        service.activateSubscription().then((res) => {
          if (res.status) {
            setChecked(true);
          } else {
            // history.push('/logout');
          }
        });
      } else {
        setChecked(false);
      }
    } else {
      if (window.confirm(t("validation.sure_unsubscribe"))) {
        service.cancelSubscription().then((res) => {
          if (res.status) {
            setChecked(false);
            setState((prev) => ({
              ...prev,
              alert: {
                show: true,
                varient: "success",
                message: "subscription cancelled sucessfully",
              },
            }));
          } else {
            setState((prev) => ({
              ...prev,
              alert: {
                show: true,
                varient: "danger",
                message: res.error.description,
              },
            }));
          }
        });
      } else {
        setChecked(true);
      }
    }
  };

  //load details
  const loadDetails = () => {
    service.subscriptionDetailsById(Id).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          product: res.data.result,
          paymentData: res.data.payment,
          userDetails: res.data.user_daetils,
          cardDetails: res.data.card_deatils,
          trustCardDetails: res.data.trust_card_details,
          loading: false,
        }));
        //setChecked(res.data.renewal);
      }
    });
  };

  const loadPaymentData = () => {
    service.getPaymentData("membership_renewal").then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          payment_methods: res.data,
          selectedPeyment: res.data[0],
        }));
      }
    });
  };

  // Payment method change event
  const changeTabHandler = (tab) => {
    setState((prevState) => ({
      ...prevState,
      selectedPeyment: state.payment_methods[tab],
    }));
  };

  // Payment Submit Event
  const submitSubscription = (event, token) => {
    // if (event.hasOwnProperty("defaultPrevented")) {
    //   event.preventDefault();
    // }
    let formData = {
      product_id: state.product.id,
      payment_method: state.selectedPeyment.code,
      user_payment_id: event
    };
    if (state.selectedPeyment.code === "ewallet") {
      formData.user_name_ewallet = state.ewalletData.username;
      formData.tran_pass_ewallet = state.ewalletData.password;
    } else if (state.selectedPeyment.code === "epin") {
      formData.pin_array = state.epinData.pinArray.length;
      state.epinData.pinArray.forEach((element, index) => {
        formData["epin" + (index + 1)] = element.pin;
      });
    } else if (state.selectedPeyment.code === "paypal") {
      formData.paypal_token = state.paypal.orderId;
      formData.PayerID = state.paypal.payeerId;
      formData.subscriptionID = state.paypal.subscriptionID;
      formData.data = state.paypal.data;

      formData.currency = "USD";
    } else if (state.selectedPeyment.code === "stripe") {
      formData.token = event;
      formData.currency = "USD";
    }
    setState((prevState) => ({
      ...prevState,
      loader: true,
    }));
    service.subscriptionSubmit(formData).then((res) => {
      console.log(res)
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          alert: {
            show: true,
            message: "Common.success",
            varient: "success",
          },
          ewalletData: {
            username: "",
            password: "",
          },
          epinData: {
            pinArray: [],
          },
          paypal: {
            orderId: "",
            payeerId: "",
          },
          selectedPeyment: "",
          selectedTab: 1,
          loader: false,
        }));
        window.scrollTo(0, 0);
      } else {
        if (res.error.code !== 1002) {
          setState({
            ...state,
            loader: false,
            alert: {
              show: true,
              message: "Common.error",
              varient: "danger",
            },
          });
          // setState((prevState) => ({
          //   ...prevState,
          //   loader: false,
          //   alert: {
          //     show: true,
          //     message: "Common.error",
          //     varient: "danger",
          //   },
          // }));
          window.scrollTo(0, { behaviour: "smooth" });
        } else {
          props.history.push("/logout");
        }
      }
    });
  };
  const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };

  //get the ewallet data
  const getEwalletData = (username, password) => {
    setState((prevState) => ({
      ...prevState,
      ewalletData: {
        username: username,
        password: password,
      },
    }));
  };

  /**
   * Epin Data
   */
  const getEpinData = (pinArray) => {
    if (pinArray.length > 0) {
      setState((prevState) => ({
        ...prevState,
        epinData: {
          pinArray,
        },
      }));
    }
  };

  const paypalData = (data) => {
    if (data) {
      setState((prevState) => ({
        ...prevState,
        paypal: {
          subscriptionID: data?.subscriptionID,
          data: data.data,
          orderId: data?.orderID,
          payeerId: data?.payerID,
        },
      }));
    }
  };
  const calculateTotalAmount = () => {
    return state.product.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue.amount_payable),
      0
    );
  };

  const { t } = props;
  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.paymentOption")}
        </title>
        {/* <script
          src={`https://www.paypal.com/sdk/js?client-id=${clientSecret}&currency=USD&vault=true`}
        ></script> */}
      </Helmet>
      <PageTitle title="paymentOption" buttonOn={false} />

      <div className={style.MainContainer}>
        {
          <Row>
            {console.log("state.product", state.product)}
            <Col>
              <Card>
                <Card.Body
                  className={`${style.ContentBody} ${style.profile_sbsc_dv}`}
                >
                  <div className="upgrade-dec">
                    <table className="table_sbscibe">
                      <tbody>
                        <tr>
                          <td>{t("Dashboard.membershipExpiry")}</td>
                          <td>:</td>

                          <td>
                            {moment(state.product[0]?.renewal_date).format(
                              "M/D/YYYY"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Renewal Charge</td>
                          <>
                            <td>:</td>
                            <td>
                              {state.product[0]?.["currency.symbol_left"]}
                              {parseFloat(calculateTotalAmount()).toFixed(2)}
                            </td>
                          </>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        }

        <Card style={{ padding: "10px" }}>
          {state.loading ? (
            <Spinner />
          ) : (
            <>
              {paymentResponse &&
                <>
                  <div className={paymentResponse.status ? paymentStyle.paymentSuccess : paymentStyle.paymentFailed}>{paymentResponse.data}</div>
                </>
              }
              <PaymentMethods
                methods={state.payment_methods}
                selectedTab={state.selectedTab}
                planId={1}
                payment_type="subscription_renewal"
                paymentMethodChange={changeTabHandler}
                submit={submitSubscription}
                updateSubscription={updateSubscription}
                amount={100}
                loader={state.loader}
                paymentData={state.paymentData}
                product={state.product}
                cardDetails={state.cardDetails}
                trustCardDetails={state.trustCardDetails}
                userDetails={state.userDetails}
                {...props}
              />
            </>
          )}
        </Card>
      </div>
    </div>
  );
}

export default withTranslation()(withRouter(SubscriptionPay));
